<template>
  <b-overlay
    :show="isAwait"
    style="min-height: 20vw; width: 100%"
    opacity="0.5"
  >
    <div
      class="dahsboard-admin-container"
    >
      <b-row
        class="align-items-center justify-content-space-between"
      >
        <b-col
          cols="12"
        >
          <b-row style="height: 34px;">
            <b-col>
              <h5>Inquéritos</h5>
            </b-col>
            <b-col
              v-if="tabIndex === 2 && quizzes.filter(el => el.state == 2 && el.result == 1).length !== 0"
              class="buttons m-0 justify-content-end buttons-group"
            >
              <download-excel
                v-if="quizzes"
                class="button primary sm"
                :fields="fieldsExportAll"
                :fetch="() => getResponses()"
                name="Respostas"
              >
                <b-icon
                  class="mr-2"
                  scale="1.2"
                  icon="file-earmark-excel-fill"
                />Exportar respostas para excel
              </download-excel>
              <download-excel
                v-if="quizzes"
                class="button primary sm"
                :fields="fieldsClassifications"
                :data="quizzes.filter(el => el.state == 2 && el.result == 1)"
                name="Classificações"
              >
                <b-icon
                  class="mr-2"
                  scale="1.2"
                  icon="file-earmark-excel-fill"
                />Exportar classificações para excel
              </download-excel>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col>
              <b-tabs
                v-model="tabIndex"
              >
                <b-tab
                  title="Por aceitar"
                  lazy
                  :title-link-class="linkClass(0)"
                >
                  <b-input-group
                    size="sm"
                    class="mt-4 mb-4"
                  >
                    <template #prepend>
                      <b-input-group-text>
                        <b-icon
                          icon="search"
                          scale="0.8"
                        />
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      id="filter-input"
                      v-model="filterAccepting"
                      type="search"
                      placeholder="Pesquisar..."
                    />

                    <b-input-group-append>
                      <b-button
                        :disabled="!filterAccepting"
                        @click="filterAccepting = ''"
                      >
                        Limpar
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-table
                    v-if="quizzes"
                    id="inAccepting"
                    class="mb-5"
                    :items="quizzes.filter(el => el.state == 1)"
                    :fields="fields"
                    :filter="filterAccepting"
                    :filter-included-fields="filterOnAccepting"
                    borderless
                    empty-text="Sem questionários"
                    empty-filtered-text="Sem questionários"
                    show-empty
                    hover
                    small
                    per-page="10"
                    :current-page="currentPageInAccepting"
                  >
                    <template #cell(actions)="row">
                      <div

                        class="buttons justify-content-start my-0 buttons-group"
                      >
                        <div
                          class="button success sm"
                          @click="accept(row.item.id)"
                        >
                          <b-icon
                            class="mr-2"
                            scale="1.2"
                            icon="check"
                          /> Aceitar
                        </div>
                        <div
                          class="button danger sm"
                          @click="reject(row.item.id)"
                        >
                          <b-icon
                            class="mr-2"
                            scale="1.2"
                            icon="trash"
                          /> Rejeitar
                        </div>
                      </div>
                    </template>
                  </b-table>
                  <b-pagination
                    v-if="quizzes"
                    v-model="currentPageInAccepting"
                    :total-rows="quizzes.filter(el => el.state == 1).length"
                    per-page="10"
                    aria-controls="inAccepting"
                  />
                </b-tab>
                <b-tab
                  title="Por aprovar"
                  lazy
                  :title-link-class="linkClass(1)"
                >
                  <b-input-group
                    size="sm"
                    class="mt-4 mb-4"
                  >
                    <template #prepend>
                      <b-input-group-text>
                        <b-icon
                          icon="search"
                          scale="0.8"
                        />
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      id="filter-input"
                      v-model="filterAccepted"
                      type="search"
                      placeholder="Pesquisar..."
                    />

                    <b-input-group-append>
                      <b-button
                        :disabled="!filterAccepted"
                        @click="filterAccepted = ''"
                      >
                        Limpar
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-table
                    v-if="quizzes"
                    id="accepted"
                    class="mb-5"
                    :items="quizzes.filter(el => el.state == 2 && el.result == -1)"
                    :fields="fields"
                    :filter="filterAccepted"
                    :filter-included-fields="filterOnAccepted"
                    borderless
                    per-page="10"
                    :current-page="currentPageAccepted"
                    empty-text="Sem questionários"
                    empty-filtered-text="Sem questionários"
                    show-empty
                    hover
                    small
                  >
                    <template #cell(actions)="row">
                      <div
                        class="buttons justify-content-start my-0 buttons-group"
                      >
                        <div
                          v-if="row.item.status === 'submitted'"
                          class="button primary xs"
                          @click="toggleDetails(row)"
                        >
                          <b-icon
                            scale="1.2"
                            :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                          />
                        </div>
                        <div
                          v-if="row.item.hasPdf"
                          class="button primary xs"
                          @click="download(row.item.quiz_id)"
                        >
                          <b-icon
                            scale="1.2"
                            icon="download"
                          />
                        </div>

                        <b-dropdown
                          class="last"
                          right
                          no-caret
                        >
                          <template #button-content>
                            <div
                              class="button primary xs m-0"
                            >
                              <b-icon
                                scale="1.2"
                                icon="three-dots"
                              />
                            </div>
                          </template>
                          <b-dropdown-item>
                            <div
                              class="button outlined no-border dark sm"
                              @click="goToQuiz(row.item.quiz_id)"
                            >
                              <b-icon
                                class="mr-2"
                                scale="1.2"
                                icon="ui-checks"
                              />Consultar questionário
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <download-excel
                              :fetch="() => getResponses(row.item.quiz_id)"
                              :fields="fieldsExport"
                              class="button outlined no-border dark sm"
                              name="Respostas"
                            >
                              <b-icon
                                class="mr-2"
                                scale="1.2"
                                icon="file-earmark-excel-fill"
                              />Exportar respostas para excel
                            </download-excel>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <div
                              v-if="row && row.item.result == -1 && row.item.status === 'submitted'"
                              class="button outlined no-border success sm"
                              @click="approve(row.item.id, row.item.quiz_id)"
                            >
                              <b-icon
                                class="mr-2"
                                scale="1.2"
                                icon="check"
                              />Aprovar
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <div
                              v-if="row && row.item.result == -1 && row.item.status === 'submitted'"
                              class="button outlined no-border danger sm"
                              @click="noApprove(row.item.id)"
                            >
                              <b-icon
                                class="mr-2"
                                scale="1.2"
                                icon="trash"
                              />Rejeitar
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                    <template #row-details="row">
                      <b-overlay
                        :show="toggling"
                        opacity="0.5"
                      >
                        <div
                          v-if="report && row.item.status === 'submitted'"
                          class="dahsboard-reports"
                        >
                          <div
                            class="report dashboard-reports"
                          >
                            <div class="report-header">
                              <div style=" align-items: center; display: flex; flex-direction: row; gap: 8px;">
                                <b>Avaliação global (ponderada): <span class="text-primary ml-2 mr-2">{{ parseFloat(report.global[0].classification).toFixed(2) }} - Nível {{ Math.floor(report.global[0].classification) }}</span></b>
                                <!--  <div
                                  v-if="report.quiz[0] && report.quiz[0].pdf"
                                  class="button primary mr-2 sm"
                                  @click="download(report.quiz[0].pdf)"
                                >
                                  <b-icon
                                    scale="1"
                                    icon="download"
                                    class="mr-3"
                                  />Download
                                </div> -->
                              </div>
                            </div>
                            <div>
                              <div class="mb-2">
                                Data: {{ report.global[0].updated_at }}
                              </div>
                              <div
                                v-if="report.global[0] && report.dimensions"
                                class="report-dimensions-classification"
                              >
                                <div class="title">
                                  Nas 6 dimensões analisadas, os níves de maturidade i4.0 são os seguintes:
                                </div>
                                <div class="row-dimensions-classification">
                                  <div class="dimensions">
                                    <div
                                      class="row-dimensions dimensions-header"
                                    >
                                      <div class="dimension">
                                        Dimensão
                                      </div>
                                      <div class="classification">
                                        Nível Atual
                                      </div>
                                      <div class="classification">
                                        Nível Futuro
                                      </div>
                                    </div>
                                    <div
                                      v-for="(dim, j) in Object.keys(report.dimensions)"
                                      :key="j"
                                      class="row-dimensions"
                                    >
                                      <div class="dimension">
                                        {{ report.dimensions[dim].name }}
                                      </div>
                                      <div class="classification">
                                        {{ report.dimensions[j].classification }}
                                      </div>
                                      <div class="classification">
                                        {{ report.future[j].value }}
                                      </div>
                                    </div>
                                    <div
                                      class="row-dimensions"
                                    >
                                      <div class="dimension">
                                        Avaliação
                                      </div>
                                      <div
                                        class="classification bg-primary text-light"
                                        style="border-top-left-radius: 5px; border-bottom-left-radius: 5px"
                                      >
                                        {{ report && report.global ? Math.floor(report.global[0].classification) : null }}
                                      </div>
                                      <div
                                        class="classification bg-primary text-light"
                                        style="border-top-right-radius: 5px; border-bottom-right-radius: 5px"
                                      >
                                        {{ Math.floor(report.future_classification) }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="classifications">
                                    <img
                                      :src="require(`@/assets/N${Math.floor(report.global[0].classification)}.svg`)"
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="report.quiz[0].svg && report.dimensions"
                                class="report-themes-classification"
                              >
                                <div class="title">
                                  Na avaliação dos temas, de cada dimensão, obteve as seguintes classificações:
                                </div>
                                <div class="row-themes-classification">
                                  <div
                                    class="svg"
                                    v-html="report.quiz[0].svg"
                                  />

                                  <div class="table">
                                    <div class="row-dimensions">
                                      <div class="dimension">
                                        Dimensão
                                      </div>
                                      <div class="themes">
                                        <div class="row-themes">
                                          <div
                                            class="theme"
                                          >
                                            Tema
                                          </div>
                                          <div
                                            class="classification"
                                          >
                                            Nível
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-for="(dim, j) in Object.keys(report.dimensions)"
                                      :key="`dimension-${j}`"
                                      class="row-dimensions"
                                    >
                                      <div
                                        class="dimension"
                                      >
                                        {{ report.dimensions[dim].name }}
                                      </div>
                                      <div
                                        class="themes"
                                      >
                                        <div
                                          v-for="(theme, i) in Object.keys(report.themes.filter(e => e.dimension_id == report.dimensions[dim].id))"
                                          :key="`theme-${i}`"
                                          class="row-themes"
                                        >
                                          <div
                                            class="theme"
                                          >
                                            {{ report.themes.filter(e => e.dimension_id == report.dimensions[dim].id)[theme].name }}
                                          </div>
                                          <div
                                            class="classification"
                                          >
                                            {{ report.themes[report.themes.indexOf(report.themes
                                              .filter(e => e.dimension_id == report.dimensions[dim].id)[theme])].classification }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          class="dahsboard-reports"
                        >
                          <div
                            class="report dashboard-reports"
                          >
                            <div class="report-header">
                              <div style=" align-items: center; display: flex; flex-direction: row; gap: 8px;">
                                <b>Sem dados</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-overlay>
                    </template>
                  </b-table>
                  <b-pagination
                    v-if="quizzes"
                    v-model="currentPageAccepted"
                    :total-rows="quizzes.filter(el => el.state == 2 && el.result == -1).length"
                    per-page="10"
                    aria-controls="accepted"
                  />
                </b-tab>
                <b-tab
                  title="Aprovados"
                  lazy
                  :title-link-class="linkClass(2)"
                >
                  <b-input-group
                    size="sm"
                    class="mt-4 mb-4"
                  >
                    <template #prepend>
                      <b-input-group-text>
                        <b-icon
                          icon="search"
                          scale="0.8"
                        />
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      id="filter-input"
                      v-model="filterApproved"
                      type="search"
                      placeholder="Pesquisar..."
                    />

                    <b-input-group-append>
                      <b-button
                        :disabled="!filterApproved"
                        @click="filterApproved = ''"
                      >
                        Limpar
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-table
                    v-if="quizzes"
                    class="mb-5"
                    :items="quizzes.filter(el => el.state == 2 && el.result == 1)"
                    :fields="fields"
                    borderless
                    empty-text="Sem questionários"
                    empty-filtered-text="Sem questionários"
                    per-page="10"
                    :current-page="currentPageApproved"
                    :filter="filterApproved"
                    :filter-included-fields="filterOnApproved"
                    show-empty
                    hover
                    small
                  >
                    <template #cell(actions)="row">
                      <div
                        class="buttons justify-content-start my-0 buttons-group"
                      >
                        <div
                          v-if="row.item.status === 'submitted'"
                          class="button primary xs"
                          @click="toggleDetails(row)"
                        >
                          <b-icon
                            scale="1.2"
                            :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                          />
                        </div>
                        <div
                          v-if="row.item.hasPdf"
                          class="button primary xs"
                          @click="download(row.item.quiz_id)"
                        >
                          <b-icon
                            scale="1.2"
                            icon="download"
                          />
                        </div>

                        <b-dropdown
                          class="last"
                          no-caret
                          right
                        >
                          <template #button-content>
                            <div
                              class="button primary xs m-0"
                            >
                              <b-icon
                                scale="1.2"
                                icon="three-dots"
                              />
                            </div>
                          </template>
                          <b-dropdown-item>
                            <div
                              class="button outlined no-border dark sm"
                              @click="goToQuiz(row.item.quiz_id)"
                            >
                              <b-icon
                                class="mr-2"
                                scale="1.2"
                                icon="ui-checks"
                              />Consultar questionário
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <download-excel
                              :fetch="() => getResponses(row.item.quiz_id)"
                              :fields="fieldsExport"
                              class="button outlined no-border dark sm"
                              name="Respostas"
                            >
                              <b-icon
                                class="mr-2"
                                scale="1.2"
                                icon="file-earmark-excel-fill"
                              />Exportar respostas para excel
                            </download-excel>
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="row && row.item.result == -1 && row.item.status === 'submitted'"
                          >
                            <div
                              class="button outlined no-border success sm"
                              @click="approve(row.item.id, row.item.quiz_id)"
                            >
                              <b-icon
                                class="mr-2"
                                scale="1.2"
                                icon="check"
                              />Aprovar
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="row && row.item.result == -1 && row.item.status === 'submitted'"
                          >
                            <div
                              class="button outlined no-border danger sm"
                              @click="noApprove(row.item.id)"
                            >
                              <b-icon
                                class="mr-2"
                                scale="1.2"
                                icon="trash"
                              />Rejeitar
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                    <template #row-details="row">
                      <b-overlay
                        :show="toggling"
                        opacity="0.5"
                      >
                        <div
                          v-if="report && row.item.status === 'submitted'"
                          class="dahsboard-reports"
                        >
                          <div
                            class="report dashboard-reports"
                          >
                            <div class="report-header">
                              <div style=" align-items: center; display: flex; flex-direction: row; gap: 8px;">
                                <b>Avaliação global (ponderada): <span class="text-primary ml-2 mr-2">{{ parseFloat(report.global[0].classification).toFixed(2) }} - Nível {{ Math.floor(report.global[0].classification) }}</span></b>
                                <!--  <div
                                  v-if="report.quiz[0] && report.quiz[0].pdf"
                                  class="button primary mr-2 sm"
                                  @click="download(report.quiz[0].pdf)"
                                >
                                  <b-icon
                                    scale="1"
                                    icon="download"
                                    class="mr-3"
                                  />Download
                                </div> -->
                              </div>
                            </div>
                            <div>
                              <div class="mb-2">
                                Data: {{ report.global[0].updated_at }}
                              </div>
                              <div
                                v-if="report.global[0] && report.dimensions"
                                class="report-dimensions-classification"
                              >
                                <div class="title">
                                  Nas 6 dimensões analisadas, os níves de maturidade i4.0 são os seguintes:
                                </div>
                                <div class="row-dimensions-classification">
                                  <div class="dimensions">
                                    <div
                                      class="row-dimensions dimensions-header"
                                    >
                                      <div class="dimension">
                                        Dimensão
                                      </div>
                                      <div class="classification">
                                        Nível Atual
                                      </div>
                                      <div class="classification">
                                        Nível Futuro
                                      </div>
                                    </div>
                                    <div
                                      v-for="(dim, j) in Object.keys(report.dimensions)"
                                      :key="j"
                                      class="row-dimensions"
                                    >
                                      <div class="dimension">
                                        {{ report.dimensions[dim].name }}
                                      </div>
                                      <div class="classification">
                                        {{ report.dimensions[j].classification }}
                                      </div>
                                      <div class="classification">
                                        {{ report.future[j].value }}
                                      </div>
                                    </div>
                                    <div
                                      class="row-dimensions"
                                    >
                                      <div class="dimension">
                                        Avaliação
                                      </div>
                                      <div
                                        class="classification bg-primary text-light"
                                        style="border-top-left-radius: 5px; border-bottom-left-radius: 5px"
                                      >
                                        {{ report && report.global ? Math.floor(report.global[0].classification) : null }}
                                      </div>
                                      <div
                                        class="classification bg-primary text-light"
                                        style="border-top-right-radius: 5px; border-bottom-right-radius: 5px"
                                      >
                                        {{ Math.floor(report.future_classification) }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="classifications">
                                    <img
                                      :src="require(`@/assets/N${Math.floor(report.global[0].classification)}.svg`)"
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="report.quiz[0].svg && report.dimensions"
                                class="report-themes-classification"
                              >
                                <div class="title">
                                  Na avaliação dos temas, de cada dimensão, obteve as seguintes classificações:
                                </div>
                                <div class="row-themes-classification">
                                  <div
                                    class="svg"
                                    v-html="report.quiz[0].svg"
                                  />

                                  <div class="table">
                                    <div class="row-dimensions">
                                      <div class="dimension">
                                        Dimensão
                                      </div>
                                      <div class="themes">
                                        <div class="row-themes">
                                          <div
                                            class="theme"
                                          >
                                            Tema
                                          </div>
                                          <div
                                            class="classification"
                                          >
                                            Nível
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-for="(dim, j) in Object.keys(report.dimensions)"
                                      :key="`dimension-${j}`"
                                      class="row-dimensions"
                                    >
                                      <div
                                        class="dimension"
                                      >
                                        {{ report.dimensions[dim].name }}
                                      </div>
                                      <div
                                        class="themes"
                                      >
                                        <div
                                          v-for="(theme, i) in Object.keys(report.themes.filter(e => e.dimension_id == report.dimensions[dim].id))"
                                          :key="`theme-${i}`"
                                          class="row-themes"
                                        >
                                          <div
                                            class="theme"
                                          >
                                            {{ report.themes.filter(e => e.dimension_id == report.dimensions[dim].id)[theme].name }}
                                          </div>
                                          <div
                                            class="classification"
                                          >
                                            {{ report.themes[report.themes.indexOf(report.themes
                                              .filter(e => e.dimension_id == report.dimensions[dim].id)[theme])].classification }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          class="dahsboard-reports"
                        >
                          <div
                            class="report dashboard-reports"
                          >
                            <div class="report-header">
                              <div style=" align-items: center; display: flex; flex-direction: row; gap: 8px;">
                                <b>Sem dados</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-overlay>
                    </template>
                  </b-table>
                  <b-pagination
                    v-if="quizzes"
                    v-model="currentPageApproved"
                    :total-rows="quizzes.filter(el => el.state == 2 && el.result == 1).length"
                    per-page="10"
                    aria-controls="approved"
                  />
                </b-tab>
                <b-tab
                  title="Não aprovados"
                  lazy
                  :title-link-class="linkClass(3)"
                >
                  <b-input-group
                    size="sm"
                    class="mt-4 mb-4"
                  >
                    <template #prepend>
                      <b-input-group-text>
                        <b-icon
                          icon="search"
                          scale="0.8"
                        />
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      id="filter-input"
                      v-model="filterNonApproved"
                      type="search"
                      placeholder="Pesquisar..."
                    />

                    <b-input-group-append>
                      <b-button
                        :disabled="!filterNonApproved"
                        @click="filterNonApproved = ''"
                      >
                        Limpar
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-table
                    v-if="quizzes"
                    id="non_approved"
                    class="mb-5"
                    :items="quizzes.filter(el => el.state == 2 && el.result == 2)"
                    :fields="fieldsWithoutAction"
                    per-page="10"
                    :current-page="currentPageNonApproved"
                    :filter="filterNonApproved"
                    :filter-included-fields="filterOnNonApproved"
                    borderless
                    empty-text="Sem questionários"
                    empty-filtered-text="Sem questionários"
                    show-empty
                    hover
                    small
                  >
                    <template #row-details="row">
                      <b-overlay
                        :show="toggling"
                        opacity="0.5"
                      >
                        <div
                          v-if="report && row.item.status === 'submitted'"
                          class="dahsboard-reports"
                        >
                          <div
                            class="report dashboard-reports"
                          >
                            <div class="report-header">
                              <div style=" align-items: center; display: flex; flex-direction: row; gap: 8px;">
                                <b>Avaliação global (ponderada): <span class="text-primary ml-2 mr-2">{{ parseFloat(report.global[0].classification).toFixed(2) }} - Nível {{ Math.floor(report.global[0].classification) }}</span></b>
                                <!-- <div
                                  v-if="report.quiz[0] && report.quiz[0].pdf"
                                  class="button primary mr-2 sm"
                                  @click="download(report.quiz[0].pdf)"
                                >
                                  <b-icon
                                    scale="1"
                                    icon="download"
                                    class="mr-3"
                                  />Download
                                </div> -->
                              </div>
                            </div>
                            <div>
                              <div class="mb-2">
                                Data: {{ report.global[0].updated_at }}
                              </div>
                              <div
                                v-if="report.global[0] && report.dimensions"
                                class="report-dimensions-classification"
                              >
                                <div class="title">
                                  Nas 6 dimensões analisadas, os níves de maturidade i4.0 são os seguintes:
                                </div>
                                <div class="row-dimensions-classification">
                                  <div class="dimensions">
                                    <div
                                      class="row-dimensions dimensions-header"
                                    >
                                      <div class="dimension">
                                        Dimensão
                                      </div>
                                      <div class="classification">
                                        Nível Atual
                                      </div>
                                      <div class="classification">
                                        Nível Futuro
                                      </div>
                                    </div>
                                    <div
                                      v-for="(dim, j) in Object.keys(report.dimensions)"
                                      :key="j"
                                      class="row-dimensions"
                                    >
                                      <div class="dimension">
                                        {{ report.dimensions[dim].name }}
                                      </div>
                                      <div class="classification">
                                        {{ report.dimensions[j].classification }}
                                      </div>
                                      <div class="classification">
                                        {{ report.future[j].value }}
                                      </div>
                                    </div>
                                    <div
                                      class="row-dimensions"
                                    >
                                      <div class="dimension">
                                        Avaliação
                                      </div>
                                      <div
                                        class="classification bg-primary text-light"
                                        style="border-top-left-radius: 5px; border-bottom-left-radius: 5px"
                                      >
                                        {{ report && report.global ? Math.floor(report.global[0].classification) : null }}
                                      </div>
                                      <div
                                        class="classification bg-primary text-light"
                                        style="border-top-right-radius: 5px; border-bottom-right-radius: 5px"
                                      >
                                        {{ Math.floor(report.future_classification) }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="classifications">
                                    <img
                                      :src="require(`@/assets/N${Math.floor(report.global[0].classification)}.svg`)"
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="report.quiz[0].svg && report.dimensions"
                                class="report-themes-classification"
                              >
                                <div class="title">
                                  Na avaliação dos temas, de cada dimensão, obteve as seguintes classificações:
                                </div>
                                <div class="row-themes-classification">
                                  <div
                                    class="svg"
                                    v-html="report.quiz[0].svg"
                                  />

                                  <div class="table">
                                    <div class="row-dimensions">
                                      <div class="dimension">
                                        Dimensão
                                      </div>
                                      <div class="themes">
                                        <div class="row-themes">
                                          <div
                                            class="theme"
                                          >
                                            Tema
                                          </div>
                                          <div
                                            class="classification"
                                          >
                                            Nível
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-for="(dim, j) in Object.keys(report.dimensions)"
                                      :key="`dimension-${j}`"
                                      class="row-dimensions"
                                    >
                                      <div
                                        class="dimension"
                                      >
                                        {{ report.dimensions[dim].name }}
                                      </div>
                                      <div
                                        class="themes"
                                      >
                                        <div
                                          v-for="(theme, i) in Object.keys(report.themes.filter(e => e.dimension_id == report.dimensions[dim].id))"
                                          :key="`theme-${i}`"
                                          class="row-themes"
                                        >
                                          <div
                                            class="theme"
                                          >
                                            {{ report.themes.filter(e => e.dimension_id == report.dimensions[dim].id)[theme].name }}
                                          </div>
                                          <div
                                            class="classification"
                                          >
                                            {{ report.themes[report.themes.indexOf(report.themes
                                              .filter(e => e.dimension_id == report.dimensions[dim].id)[theme])].classification }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          class="dahsboard-reports"
                        >
                          <div
                            class="report dashboard-reports"
                          >
                            <div class="report-header">
                              <div style=" align-items: center; display: flex; flex-direction: row; gap: 8px;">
                                <b>Sem dados</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-overlay>
                    </template>
                  </b-table>
                  <b-pagination
                    v-if="quizzes"
                    v-model="currentPageNonApproved"
                    :total-rows="quizzes.filter(el => el.state == 2 && el.result == 2).length"
                    per-page="10"
                    aria-controls="non_approved"
                  />
                </b-tab>
                <b-tab
                  title="Meus Relatórios"
                  lazy
                  :title-link-class="linkClass(4)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form
                        @submit="submitForm"
                      >
                        <b-form-group
                          label="Questionário"
                          class="mt-4 mb-4"
                        >
                          <b-form-select
                            v-model="quizDocument.id"
                            required
                          >
                            <option
                              v-for="(option, idx) in quizzes"
                              :key="idx"
                              :value="option.id"
                              :title="option.name || null"
                            >
                              ({{ option.id }}) {{ option.name }} - {{ formatDate(option.submitted_at) }} ({{ option.classification }}/5)
                            </option>
                          </b-form-select>
                        </b-form-group>
                        <b-form-group
                          label="Ficheiro"
                          description="Tamanho máximo: 2 MB. Tipos de ficheiros aceites: .pdf"
                        >
                          <b-form-file
                            id="file"
                            v-model="quizDocument.file"
                            required
                            accept="application/pdf"
                            :state="quizDocument.file ? (quizDocument.file.size / 1024 / 1024 > 2 ? false : true) : null "
                            placeholder="Selecione um ficheiro ou mova-o para aqui..."
                            drop-placeholder="Largue o ficheiro aqui..."
                          />
                        </b-form-group>
                        <div class="buttons justify-content-start">
                          <b-button
                            type="submit"
                            class="button primary sm"
                            variant="primary"
                            :disabled="!(quizDocument.id && quizDocument.file && quizDocument.file.size / 1024 / 1024 <= 2)"
                          >
                            <b-icon icon="cloud-upload" /> Carregar
                          </b-button>
                        </div>
                      </b-form>
                    </b-col>
                  </b-row>

                  <b-table
                    v-if="quizzes"
                    id="reports"
                    class="mb-5"
                    :items="quizzes.filter(el => el.report != null)"
                    :fields="fields"
                    :filter="filterReports"
                    :filter-included-fields="filterOnReports"
                    borderless
                    empty-text="Sem questionários"
                    empty-filtered-text="Sem questionários"
                    show-empty
                    hover
                    small
                    per-page="10"
                    :current-page="currentPageReports"
                  >
                    <template #cell(actions)="row">
                      <div

                        class="buttons justify-content-start my-0 buttons-group"
                      >
                        <div
                          v-if="row.item.status === 'submitted'"
                          class="button primary xs"
                          @click="toggleDetails(row)"
                        >
                          <b-icon
                            scale="1.2"
                            :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                          />
                        </div>
                        <div
                          v-if="row.item.hasPdf"
                          class="button primary xs"
                          @click="downloadReport(row.item.report, row.item.filename)"
                        >
                          <b-icon
                            scale="1.2"
                            icon="download"
                          />
                        </div>
                      </div>
                    </template>
                    <template #row-details="row">
                      <b-overlay
                        :show="toggling"
                        opacity="0.5"
                      >
                        <div
                          v-if="report && row.item.status === 'submitted'"
                          class="dahsboard-reports"
                        >
                          <div
                            class="report dashboard-reports"
                          >
                            <div class="report-header">
                              <div style=" align-items: center; display: flex; flex-direction: row; gap: 8px;">
                                <b>Avaliação global (ponderada): <span class="text-primary ml-2 mr-2">{{ parseFloat(report.global[0].classification).toFixed(2) }} - Nível {{ Math.floor(report.global[0].classification) }}</span></b>
                                <!--  <div
                                  v-if="report.quiz[0] && report.quiz[0].pdf"
                                  class="button primary mr-2 sm"
                                  @click="download(report.quiz[0].pdf)"
                                >
                                  <b-icon
                                    scale="1"
                                    icon="download"
                                    class="mr-3"
                                  />Download
                                </div> -->
                              </div>
                            </div>
                            <div>
                              <div class="mb-2">
                                Data: {{ report.global[0].updated_at }}
                              </div>
                              <div
                                v-if="report.global[0] && report.dimensions"
                                class="report-dimensions-classification"
                              >
                                <div class="title">
                                  Nas 6 dimensões analisadas, os níves de maturidade i4.0 são os seguintes:
                                </div>
                                <div class="row-dimensions-classification">
                                  <div class="dimensions">
                                    <div
                                      class="row-dimensions dimensions-header"
                                    >
                                      <div class="dimension">
                                        Dimensão
                                      </div>
                                      <div class="classification">
                                        Nível Atual
                                      </div>
                                      <div class="classification">
                                        Nível Futuro
                                      </div>
                                    </div>
                                    <div
                                      v-for="(dim, j) in Object.keys(report.dimensions)"
                                      :key="j"
                                      class="row-dimensions"
                                    >
                                      <div class="dimension">
                                        {{ report.dimensions[dim].name }}
                                      </div>
                                      <div class="classification">
                                        {{ report.dimensions[j].classification }}
                                      </div>
                                      <div class="classification">
                                        {{ report.future[j].value }}
                                      </div>
                                    </div>
                                    <div
                                      class="row-dimensions"
                                    >
                                      <div class="dimension">
                                        Avaliação
                                      </div>
                                      <div
                                        class="classification bg-primary text-light"
                                        style="border-top-left-radius: 5px; border-bottom-left-radius: 5px"
                                      >
                                        {{ report && report.global ? Math.floor(report.global[0].classification) : null }}
                                      </div>
                                      <div
                                        class="classification bg-primary text-light"
                                        style="border-top-right-radius: 5px; border-bottom-right-radius: 5px"
                                      >
                                        {{ Math.floor(report.future_classification) }}
                                      </div>
                                    </div>
                                  </div>

                                  <div class="classifications">
                                    <img
                                      :src="require(`@/assets/N${Math.floor(report.global[0].classification)}.svg`)"
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="report.quiz[0].svg && report.dimensions"
                                class="report-themes-classification"
                              >
                                <div class="title">
                                  Na avaliação dos temas, de cada dimensão, obteve as seguintes classificações:
                                </div>
                                <div class="row-themes-classification">
                                  <div
                                    class="svg"
                                    v-html="report.quiz[0].svg"
                                  />

                                  <div class="table">
                                    <div class="row-dimensions">
                                      <div class="dimension">
                                        Dimensão
                                      </div>
                                      <div class="themes">
                                        <div class="row-themes">
                                          <div
                                            class="theme"
                                          >
                                            Tema
                                          </div>
                                          <div
                                            class="classification"
                                          >
                                            Nível
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-for="(dim, j) in Object.keys(report.dimensions)"
                                      :key="`dimension-${j}`"
                                      class="row-dimensions"
                                    >
                                      <div
                                        class="dimension"
                                      >
                                        {{ report.dimensions[dim].name }}
                                      </div>
                                      <div
                                        class="themes"
                                      >
                                        <div
                                          v-for="(theme, i) in Object.keys(report.themes.filter(e => e.dimension_id == report.dimensions[dim].id))"
                                          :key="`theme-${i}`"
                                          class="row-themes"
                                        >
                                          <div
                                            class="theme"
                                          >
                                            {{ report.themes.filter(e => e.dimension_id == report.dimensions[dim].id)[theme].name }}
                                          </div>
                                          <div
                                            class="classification"
                                          >
                                            {{ report.themes[report.themes.indexOf(report.themes
                                              .filter(e => e.dimension_id == report.dimensions[dim].id)[theme])].classification }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          class="dahsboard-reports"
                        >
                          <div
                            class="report dashboard-reports"
                          >
                            <div class="report-header">
                              <div style=" align-items: center; display: flex; flex-direction: row; gap: 8px;">
                                <b>Sem dados</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-overlay>
                    </template>
                  </b-table>
                  <b-pagination
                    v-if="quizzes"
                    v-model="currentPageReports"
                    :total-rows="quizzes.filter(el => el.report != null).length"
                    per-page="10"
                    aria-controls="reports"
                  />
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="dots"
      centered
      hide-footer
      header-class="border-bottom-0"
      footer-class="border-top-0"
      size="lg"
      title="Mais ações"
    >
      <div
        class="buttons"
        style="display: flex; flex-wrap: wrap;"
      />
    </b-modal>
    <b-modal
      id="reportReplace"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      centered
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Substituir Relaórios
        </div>

        <div class="text-dark text-center">
          Já existe um relatório de facilitador associado a este questionário. Pretende substituí-lo?
        </div>

        <div class="buttons">
          <button
            class="button secondary"
            type="submit"
            @click="() => {
              quizDocument = { id: null, file: null }
              $bvModal.hide('reportReplace');
            }"
          >
            Não
          </button>
          <button
            class="button primary"
            type="submit"
            @click="uploadFile"
          >
            Sim
          </button>
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  get, post, postFile, put,
} from '../../services/api';

export default {
  name: 'Facilitator',
  props: {
    setState: {
      type: Function,
      default: () => {},
    },
    links: {
      type: Array,
      default: null,
    },
    state: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      quizDocument: {
        id: null,
        file: null,
      },
      currentPageInAccepting: 1,
      currentPageAccepted: 1,
      currentPageApproved: 1,
      currentPageNonApproved: 1,
      currentPageReports: 1,
      toggling: false,
      tempRow: null,
      isAwait: false,
      quizzes: null,
      facilitatorReports: null,
      report: null,
      tabIndex: 0,
      filterAccepting: null,
      filterAccepted: null,
      filterApproved: null,
      filterReports: null,
      filterNonApproved: null,
      filterOnAccepting: ['name', 'submitted_at', 'classification', 'final'],
      filterOnAccepted: ['name', 'submitted_at', 'classification', 'final'],
      filterOnApproved: ['name', 'submitted_at', 'classification', 'final'],
      filterOnNonApproved: ['name', 'submitted_at', 'classification', 'final'],
      filterOnReports: ['name', 'submitted_at', 'classification', 'final'],
      fieldsExport: {
        Dimensão: 'dimension',
        Questão: 'question',
        Opção: 'option',
        'Label do valor': 'label',
        Valor: 'value',
        'Código da dimensão': 'dimension_code',
        'Código da questão': 'question_code',
        'Tipo de questão': 'question_type',
        'Código do valor': 'value_code',
      },
      fieldsExportAll: {
        Empresa: 'company_name',
        'ID do questionário': 'id',
        'Data de submissão': {
          field: 'updated_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getDate().toString().length < 2 ? `0${date.getDate()}` : date.getDate(),
              (date.getMonth() + 1).toString().length < 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        Dimensão: 'dimension',
        Questão: 'question',
        Opção: 'option',
        'Label do valor': 'label',
        Valor: 'value',
        'Código da dimensão': 'dimension_code',
        'Código da questão': 'question_code',
        'Tipo de questão': 'question_type',
        'Código do valor': 'value_code',
      },
      fieldsClassifications: {
        Empresa: 'name',
        Questionário: 'quiz_id',
        'Submetido em': {
          field: 'submitted_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getDate().toString().length < 2 ? `0${date.getDate()}` : date.getDate(),
              (date.getMonth() + 1).toString().length < 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        Classificação: {
          field: 'classification',
          callback: (value) => parseFloat(value).toFixed(2),
        },
        'Grau de maturidade': {
          field: 'final',
          callback: (value) => Math.floor(value),
        },
      },
      fields: [
        {
          key: 'name',
          label: 'Empresa',
          sortable: true,

          thStyle: { width: '20%' },
        },
        {
          key: 'submitted_at',
          label: 'Submetido em',
          formatter: (value, key, item) => (item.status === 'submitted'
            ? value.split(' ')[0].split('-').reverse().join('/')
            : ''),
          sortable: true,

          thStyle: { width: '20%' },
        },
        {
          key: 'classification',
          label: 'Classificação',
          formatter: (value, key, item) => (item.status === 'submitted'
            ? parseFloat(value).toFixed(2)
            : ''),
          sortable: true,

          thStyle: { width: '20%' },
        },
        {
          key: 'final',
          label: 'Grau de maturidade',
          formatter: (value, key, item) => (item.status === 'submitted'
            ? Math.floor(value)
            : ''),
          sortable: true,

          thStyle: { width: '20%' },
        },
        {
          key: 'actions',
          label: 'Ações',
          thStyle: { width: '15%' },
        },
      ],
      fieldsWithoutAction: [
        {
          key: 'name',
          label: 'Empresa',
          sortable: true,

          thStyle: { width: '22.5%' },
        },
        {
          key: 'submitted_at',
          label: 'Submetido em',
          formatter: (value, key, item) => (item.status === 'submitted'
            ? value.split(' ')[0].split('-').reverse().join('/')
            : ''),
          sortable: true,

          thStyle: { width: '22.5%' },
        },
        {
          key: 'classification',
          label: 'Classificação',
          formatter: (value, key, item) => (item.status === 'submitted'
            ? parseFloat(value).toFixed(2)
            : ''),
          sortable: true,

          thStyle: { width: '22.5%' },
        },
        {
          key: 'final',
          label: 'Grau de maturidade',
          formatter: (value, key, item) => (item.status === 'submitted'
            ? Math.floor(value)
            : ''),
          sortable: true,
          thStyle: { width: '22.5%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '10%' },
        },
      ],
    };
  },
  async created() {
    const { condition } = this.links.find((el) => el.state === this.state);
    if (typeof condition === 'function') {
      if (await !condition()) {
        this.setState(2);
      }
    } else if (!condition) {
      this.setState(2);
    }
    await this.getQuizzes();
  },
  methods: {
    validateSize(evt) {
      const fileSize = evt.target.files[0].size / 1024 / 1024;
      if (fileSize <= 2) {
        this.quizDocument.file = evt.target.files[0];
      } else {
        this.quizDocument.file = null;
      }
    },
    async submitForm(event) {
      event.preventDefault();
      const quiz = this.quizzes.find((el) => el.id === this.quizDocument.id);

      if (quiz && quiz.report) {
        this.$bvModal.show('reportReplace');
      } else if (quiz) {
        await this.uploadFile();
      } else {
        this.quizDocument = { id: null, file: null };
      }
    },
    async uploadFile() {
      const quiz = this.quizzes.find((el) => el.id === this.quizDocument.id);
      if (quiz) {
        this.isAwait = true;
        const formData = new FormData();
        formData.append('file', this.quizDocument.file, this.quizDocument.file.name);
        formData.append('id', this.quizDocument.id);
        await postFile(`facilitator_reports/${this.quizDocument.id}`, formData)
          .then(() => {
            this.getQuizzes();
            this.quizDocument = { id: null, file: null };
            this.$bvModal.hide('reportReplace');
          }).catch((err) => {
            this.isAwait = false;
            this.$bvModal.hide('reportReplace');
          });
        this.isAwait = false;
        this.$bvModal.hide('reportReplace');
      }
    },
    formatDate(value) {
      const date = new Date(value);
      return [
        date.getDate().toString().length < 2 ? `0${date.getDate()}` : date.getDate(),
        (date.getMonth() + 1).toString().length < 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
        date.getFullYear(),
      ].join('/');
    },
    async downloadReport(report, filename) {
      const linkSource = `data:application/${filename.split('.').slice(-1)};base64,${report}`;
      const downloadLink = document.createElement('a');
      const fileName = filename;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    async download(id) {
      await get(`quiz/${id}`).then((response) => {
        const linkSource = `data:application/pdf;base64,${response.data.pdf}`;
        const downloadLink = document.createElement('a');
        const fileName = 'RelatórioI4.0.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });

      /*  const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement('a');
      const fileName = 'RelatórioI4.0.pdf';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click(); */
    },
    async getQuizzes() {
      this.isAwait = true;
      await get('quiz_facilitator')
        .then((response) => {
          this.isAwait = false;
          this.quizzes = response.data;
        }).catch((error) => {
          this.isAwait = false;
        });
    },
    async accept(quizFacilitator) {
      await put(`quiz_facilitator/${quizFacilitator}`, {
        state: 2,
      })
        .then(async (response) => {
          this.isAwait = false;
          await this.getQuizzes();
          // console.log(response);
        }).catch((error) => {
          this.isAwait = false;
        });
      this.$bvModal.hide('dots');
    },
    async reject(quizFacilitator) {
      await put(`quiz_facilitator/${quizFacilitator}`, {
        state: 3,
      })
        .then(async (response) => {
          this.isAwait = false;
          await this.getQuizzes();
          // console.log(response);
        }).catch((error) => {
          this.isAwait = false;
        });
      this.$bvModal.hide('dots');
    },

    async approve(quizFacilitator, quizId) {
      await put(`quiz_facilitator/${quizFacilitator}`, {
        result: 1,
      })
        .then(async (response) => {
          this.isAwait = false;

          post(`generate-pdf/${quizId}`, {});
          await this.getQuizzes();
          // console.log(response);
        }).catch((error) => {
          this.isAwait = false;
        });
      this.$bvModal.hide('dots');
    },
    async noApprove(quizFacilitator) {
      await put(`quiz_facilitator/${quizFacilitator}`, {
        result: 2,
      })
        .then(async (response) => {
          this.isAwait = false;
          await this.getQuizzes();
        }).catch((error) => {
          this.isAwait = false;
        });
      this.$bvModal.hide('dots');
    },
    goToQuiz(quiz) {
      this.$store.commit('setDisabled', true);
      this.$store.commit('setQuiz', quiz);
      this.$router.push({ name: 'quiz' });
    },
    async openReport(id) {
      await get(`score/${id}`, {})
        .then((response) => {
          this.report = response.data;
        });
    },
    async toggleDetails(row) {
      this.toggling = true;
      row.toggleDetails();
      if (!row.detailsShowing) {
        await this.openReport(row.item.quiz_id);
      }
      this.toggling = false;
    },
    async getResponses(id) {
      if (id) {
        const response = await get(`responses/${id}`);
        return response.data.responses;
      }
      const response = await get('responses/');
      return response.data.responses;
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['text-primary'];
      }
      return ['text-secondary'];
    },
  },
};
</script>
